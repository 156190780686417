var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{attrs:{"id":"nav"}},[_c('img',{staticClass:"logo",attrs:{"src":require("../public/images/logo_stable_trnspr.png"),"alt":""},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}}),_c('i',{class:{
        'fas fa-bars menu-bar': _vm.showMenu === false,
        'fas fa-times menu-bar': _vm.showMenu,
      },on:{"click":function($event){return _vm.showMenuList()}}}),_c('div',{staticClass:"nav",class:{ hide: _vm.showMenu === false, show: _vm.showMenu }},[_c('router-link',{staticClass:"link",attrs:{"to":"/"}},[_vm._v(_vm._s(_vm.$t("links[0].title")))]),_c('router-link',{staticClass:"link",attrs:{"to":"/artworks"}},[_vm._v(_vm._s(_vm.$t("links[2].title")))]),_c('router-link',{staticClass:"link",attrs:{"to":"/projects"}},[_vm._v(_vm._s(_vm.$t("links[3].title")))]),_c('router-link',{staticClass:"link",attrs:{"to":"/exhibitions"}},[_vm._v(_vm._s(_vm.$t("links[4].title")))]),_c('router-link',{staticClass:"link",attrs:{"to":"/biography"}},[_vm._v(_vm._s(_vm.$t("links[1].title")))]),_c('router-link',{staticClass:"link",attrs:{"to":"/contact"}},[_vm._v(_vm._s(_vm.$t("links[5].title")))]),_c('local-switcher'),_c('div',{staticClass:"logged-icons"},[(_vm.loggedIn)?_c('a',{staticClass:"logout",on:{"click":function($event){return _vm.logout()}}},[_vm._v("LOGOUT")]):_vm._e(),(_vm.loggedIn)?_c('router-link',{staticClass:"link",attrs:{"to":"/admin"}},[_c('i',{staticClass:"far fa-edit prof-link"})]):_vm._e()],1)],1)]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{class:{ 'no-show': _vm.loader }})],1),(_vm.loader)?_c('Loader'):_vm._e(),_c('footer',[_c('Footer')],1),_c('custom-cursor',{staticClass:"custom-curs",attrs:{"targets":[
      'a',
      'button',
      'i',
      'nav-left',
      'nav-scroll',
      'order-nav',
      'dtls-nav',
      'order-exit',
      'exit-order',
      'order-delete',
      'exit-order',
      'check-order-btn',
      'buy-nav',
      'exit',
      'prev-img',
      'lines',
      'delete',
      'ctrl',
      'img-gallery',
      'logo',
      'image-home',
      'flag',
      'cart-icon',
      'cart-amount',
      'add-icon',
      'img-prof',
      'cover-img',
      'conct-mail' ],"circleColor":'#27f2cb',"circleColorHover":'#bebebe',"hoverSize":4}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }